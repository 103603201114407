module services {
    export module lc {
        export class letterOfCreditLineService implements interfaces.lc.ILetterOfCreditLineService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getList(): ng.resource.IResourceClass<interfaces.lc.ILetterOfCreditLineDisplay> {
                return this.$resource<interfaces.lc.ILetterOfCreditLineDisplay>(this.ENV.DSP_URL + "LetterOfCredit/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        params: {
                            numberRecords: 25,
                            pageNumber: 1,
                        }
                    }
                });
            }

            getLetterOfCreditLineDetail(letterOfCreditLineId: number, letterOfCreditId: number): ng.resource.IResourceClass<interfaces.lc.ILetterOfCreditLine> {
                return this.$resource<interfaces.lc.ILetterOfCreditLine>(this.ENV.DSP_URL + "LetterOfCreditLine/GetLetterOfCreditLineDetail", {
                    letterOfCreditLineId: letterOfCreditLineId,
                    letterOfCreditId: letterOfCreditId,
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }


            getLetterOfCreditLines(Id: number): ng.resource.IResourceClass<interfaces.lc.ILetterOfCreditLine> {
                return this.$resource<interfaces.lc.ILetterOfCreditLine>(this.ENV.DSP_URL + "LetterOfCreditLine/GetLetterOfCreditLines", {
                    Id: Id
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCreditLine/Save");
            }

        }
    }

    angular.module("app").service("letterOfCreditLineService", services.lc.letterOfCreditLineService);
}